import React from 'react';
import Grid from './Grid';

function App() {
  return (
    <div className="App">
      <h1> HUNDRED ORIGINAL MEMES H.O.M.E.</h1>
      <Grid />
    </div>
  );
}

export default App;
