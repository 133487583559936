import React from 'react';
import './Footer.css'; // Create and use a separate CSS file for the footer styles

const Footer = () => {
  return (
    <footer className="footer">
      <a href="https://traderjoexyz.com/avalanche/trade?outputCurrency=0x4586AF10ecceeD4E383e3f2Ec93b6C61e26500b5" target="_blank" rel="noopener noreferrer">
      <img src="/Logo.png" alt="Logo" className="footer-logo" />
      </a>
      <p className="footer-slogan">#KeepIt<span className='emoji'>💯</span></p>
    </footer>
  );
};

export default Footer;
